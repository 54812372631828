const parseMLBfile = (csvString, needParseType) => {
  if (needParseType === undefined || needParseType === null) {
    needParseType = false;
  }

  const lines = csvString.trim().split('\n');
  const headers = lines[1].split('\t');
  const dataTypes = lines[2].split('\t');

  const jsonData = [];

  for (let i = 3; i < lines.length; i++) {
    const currentLine = lines[i].split('\t');
    const data = {};
    for (let j = 0; j < headers.length; j++) {
      headers[j] = headers[j].trim();
      let stringData = currentLine[j].trim();

      if (!needParseType) {
        data[headers[j]] = stringData;
        continue;
      }

      let dataType = dataTypes[j].trim().split('|')[0];
      if (dataType === "int" || dataType === "byte") {
        data[headers[j]] = parseInt(stringData);
      }
      else if (dataType === "float") {
        data[headers[j]] = parseFloat(stringData);
      }
      else {
        data[headers[j]] = stringData;
      }
    }

    jsonData.push(data);
  }

  return jsonData;
};

export default parseMLBfile;